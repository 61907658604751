import { V3dTools } from '@/v3d/common';
import { iApp } from '@/Interfaces/app';
import { Camera } from './camera';
import { loader } from '@/Engine/loader';
import { IV3D } from '@/Interfaces/v3d';
import { $ } from '@/Engine/state';
import { gridDivisions, gridEnabled, gridSize } from '@/Constants/3dConfig';
import {Vector3} from "three";
import {Item} from "@/Engine/Item";


declare let v3d: IV3D;
export const app = async function(app: iApp) {
    $.app = app;
    // app.controls.enableRotate = true;
    // app.controls.enablePan = true;
    // app.controls.enableKeys = true;
    // app.hideFPS();
    app.showFPS();
    // app.disableRendering(0);

    await loader('Brick.glb', (scene) => {
            scene.children.forEach((item) => {
                item.visible = false;
            });
            // modelNames.forEach((modelName) => {
            //     const model = scene.getObjectByName(modelName);
            //     if (model)
            //         model.visible = false;
            // });
        }, (progress) => {
            // console.log(progress);
        },
        app,
    );

    // app.render();
    // const render = (cb?: () => void) => requestAnimationFrame(() => {
    //     app.render();
    //     if (cb) cb();
    // });

    // $.render = render;
    const v3dTools = new V3dTools(app);
    $.v3dTools = v3dTools;

    const camera = new Camera(app.getCamera(), v3dTools);

    if (gridEnabled) {
        $.grid = new v3d.GridHelper(gridSize, gridDivisions);
        app.scene.add($.grid);
    }


    const containerParent = document.getElementById('v3d-container') as HTMLElement;
    $.container = containerParent;
    $.camera = camera;

    // window.addEventListener('resize', () => {
    //     $.render();
    // });
    // app.controls.addEventListener('change', () => {
    //     $.render();
    // });

    const sleep = async (time: number) => {
        return new Promise((resolve)=>{
            setTimeout(()=>{
                $.render(()=>{
                    resolve(null);
                });
            }, time);
        });
    }

    const moveCameraForScreenshot = (target: Vector3, position: Vector3) => {
        return new Promise((resolve)=>{
            //TODO высчитывать позицию камеры
            $.camera.updateCam(
                target,
                position,
            );
            setTimeout(()=>{
                $.render(()=>{
                    resolve(null);
                });
            }, 50);
        });
    }
    // app.camera.position.x = 5;
    // app.camera.lookAt();
    // const wall = new Item({blender_name: 'wall'});
    // wall.position = new Vector3(5.75,2.21,0);
    // app.scene.add(wall.model);
    camera.updateCam(new Vector3(5, 2, 0), new Vector3(5,4,10))
    for (let column = 0, yPos = 0; column <= 10; column++, yPos+=8.56) {
        for (let row = 0; row <= 100; row+=15.25) {
            const brick = new Item({blender_name: 'brick'});
            brick.position = new Vector3(row, yPos, 0);
            app.scene.add(brick.model);
        }
    }





    // $.render();
};
