import {xy, xyz} from "@/Interfaces/common";

export const height = 1830;
export const depth = 500;

export interface iModelMeta {
    [key: string]: {
        width?: number,
        offset?: xyz,
        scale?: xyz,
    }
}
export const modelMeta: iModelMeta = {
    "brick": {
        offset: [0,0,0],
        scale:[0.0254,0.0254,0.0254],
    },
    "wall": {
        offset: [0,0,0],
        scale:[5.2,2.2,0.2],
    },
}
export const modelNames = Object.keys(modelMeta);

export type modelNamesT = keyof typeof modelMeta | '';
