import { V3dTools } from '@/v3d/common';
import { IV3D } from '@/Interfaces/v3d';
import { iApp } from '@/Interfaces/app';
import { Font } from 'three/examples/jsm/loaders/FontLoader';
import { Locker } from '@/Engine/Locker';
import { Camera } from '@/Engine/camera';
import {GridHelper} from "three";


declare let v3d: IV3D;
export class GlobalState {
    container?: HTMLElement;
    v3dTools!: V3dTools;
    pointer = new v3d.Vector2();
    app!: iApp;
    font: Font | undefined;
    render!: (cb?: ()=>void)=>void;
    lockers: Locker[] = [];
    camera!: Camera;
    defaultBackground!: any;
    grid!: GridHelper;

    constructor() {
    }
}

export const $ = new GlobalState();

declare global {
    interface Window {
        app: GlobalState
    }
}

window.app = $;
