import { Box3, Object3D, Quaternion, Ray, Vector3 } from 'three';
import { IV3D } from '@/Interfaces/v3d';
import { v3dMaterial, v3dMesh, xyz } from '@/Interfaces/common';
import changeColor from '@/utils/changeColor';
import { $ } from '@/Engine/state';
import {mod} from "three/examples/jsm/nodes/shadernode/ShaderNodeBaseElements";
import {modelMeta, modelNamesT} from "@/Constants/modelMeta";


declare let v3d: IV3D;

export interface itemProps {
    // color: string;
    blender_name: modelNamesT;
}
export class Item {
    private readonly _model!: v3dMesh;
    public model: Object3D;
    public box: Box3 = new Box3();
    private materials: v3dMaterial[] = [];
    public offsets!: xyz;
    public scale!: xyz;
    color!: string;

    set position(position: Vector3) {
        if (position.distanceTo(this.model.position) < 0.001) return;
        this.model.position.copy(position);
    }

    set yRotation(angle: number) {
        if (!this._model) return;
        this._model.rotation.y = angle;
    }
    get position() {
        return this.model.position.clone();
    }

    get quaternion() {
        const quaternion = new Quaternion();
        this.model.getWorldQuaternion(quaternion);
        return quaternion;
    }

    rotate(point: Vector3) {
        return point.clone().applyQuaternion(this.quaternion);
    }

    show() {
        // $.app.scene.add(this.model);
        // this._model.visible = true;
    }

    hide() {
        this._model.removeFromParent();
        // this._model.visible = false;
    }

    constructor({ blender_name }: itemProps) {
        this.model = new v3d.Group();
        this.model.userData.parent = this;
        if (!blender_name) {
            console.warn('Blender_name not set');
            return;
        }
        // this.color = color;
        const modelProto = $.app.scene.getObjectByName(blender_name as string);

        // console.log(blender_name, modelProto)
        if (!modelProto) {
            console.warn('Not found model ', blender_name);
            return;
        }
        this._model = modelProto.clone() as v3dMesh;
        // if (this._model.material) {
        //     this._model.material = this._model.material.clone();
        //     this.materials.push(this._model.material);
        // } else {
        //     // console.log(this._model)
        //     this._model.traverse((object) => {
        //         const child = object as v3dMesh;
        //         if (child.material && child.material.name === 'material_01') {
        //             child.material = child.material.clone();
        //             this.materials.push(child.material);
        //         }
        //
        //     })
        // }

        // this._model.material.name = (100*Math.random()).toFixed(0);
        this._model.visible = true;
        // this._model.name = uuid;
        // this.offsets = offsets || [ 0,0,0 ];
        const offset = modelMeta[blender_name]?.offset
        if (offset) {
            this.offsets = offset;
        } else {
            this.offsets = [ 0, 0, 0 ];
        }

        const scale = modelMeta[blender_name]?.scale
        if (scale) {
            this.scale = scale;
            this._model.scale.set(...this.scale);
        }

        // this.rotationDeg = rotation || [ 0,0,0 ];
        // this.rotationRad = this.rotationDeg.map((angle)=>angle*Math.PI/180) as xyz;
        this._model.position.set(...this.offsets);
        // this._model.rotation.set(...this.rotationRad);
        // if (scale)

        this.model.add(this._model);
        // this.show();
        // if (color)`
        //     this.setColor(color);
    }

    setColor(hex: string) {
        this.color = hex.replace('#','');
        const color = hex.startsWith('#')?hex:'#'+hex;
        this.materials.forEach((material)=>{
            changeColor(material, 'RGB', color);
        })

    }

    destroy() {
        this.model.removeFromParent();
    }
}
