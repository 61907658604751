export const Filter = class {
    constructor(c) {
        this.c = c;
        this.alfa = 1 - Math.exp(-this.c);
        this.gg1 = null;
    }

    newValue(g) {
        if (!this.gg1) this.gg1 = g;

        this.gg = (1 - this.alfa) * this.gg1 + this.alfa * g;
        this.gg1 = this.gg;
        return this.gg;
    }
};
