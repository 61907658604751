import { Euler, Event, Mesh, Object3D } from 'three';
import { IV3D } from '@/Interfaces/v3d';
import { iApp } from '@/Interfaces/app';
import { v3dMesh } from '@/Interfaces/common';

declare let v3d: IV3D;

export class V3dTools {
    _pGlob: any;
    private appInstance: iApp;

    constructor(appInstance: iApp) {
        this.appInstance = appInstance;
        const LIST_NONE = '<none>';

        this._pGlob = {};
        this._pGlob.animMixerCallbacks = [];
        this._pGlob.objCache = {};
        this._pGlob.fadeAnnotations = true;
        this._pGlob.pickedObject = '';
        this._pGlob.hoveredObject = '';
        this._pGlob.mediaElements = {};
        this._pGlob.loadedFile = '';
        this._pGlob.states = [];
        this._pGlob.percentage = 0;
        this._pGlob.openedFile = '';
        this._pGlob.xrSessionAcquired = false;
        this._pGlob.xrSessionCallbacks = [];
        this._pGlob.screenCoords = new v3d.Vector2();
        this._pGlob.intervalTimers = {};

        this._pGlob.AXIS_X = new v3d.Vector3(1, 0, 0);
        this._pGlob.AXIS_Y = new v3d.Vector3(0, 1, 0);
        this._pGlob.AXIS_Z = new v3d.Vector3(0, 0, 1);
        this._pGlob.MIN_DRAG_SCALE = 10e-4;
        this._pGlob.SET_OBJ_ROT_EPS = 1e-8;

        this._pGlob.vec2Tmp = new v3d.Vector2();
        this._pGlob.vec2Tmp2 = new v3d.Vector2();
        this._pGlob.vec3Tmp = new v3d.Vector3();
        this._pGlob.vec3Tmp2 = new v3d.Vector3();
        this._pGlob.vec3Tmp3 = new v3d.Vector3();
        this._pGlob.vec3Tmp4 = new v3d.Vector3();
        this._pGlob.eulerTmp = new v3d.Euler();
        this._pGlob.eulerTmp2 = new v3d.Euler();
        this._pGlob.quatTmp = new v3d.Quaternion();
        this._pGlob.quatTmp2 = new v3d.Quaternion();
        this._pGlob.colorTmp = new v3d.Color();
        this._pGlob.mat4Tmp = new v3d.Matrix4();
        this._pGlob.planeTmp = new v3d.Plane();
        this._pGlob.raycasterTmp = new v3d.Raycaster();
        this._pGlob.bindHTMLCallbackInfo = [];
    }

    // utility function envoked by almost all V3D-specific puzzles
    // filter off some non-mesh types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    notIgnoredObj(obj) {
        return (
            obj.type !== 'AmbientLight' &&
            obj.name !== '' &&
            !(obj.isMesh && obj.isMaterialGeneratedMesh) &&
            !obj.isAuxClippingMesh
        );
    }

    // utility function envoked by almost all V3D-specific puzzles
    // find first occurence of the object by its name
    getObjectByName(objName: string): v3dMesh | null {
        let objFound: null | Object3D = null;
        const runTime = this._pGlob !== undefined;
        objFound = runTime ? this._pGlob.objCache[objName] : null;

        if (objFound && objFound.name === objName) return objFound as v3dMesh;

        this.appInstance.scene.traverse((obj) => {
            if (!objFound && this.notIgnoredObj(obj) && obj.name == objName) {
                objFound = obj;
                if (runTime) {
                    this._pGlob.objCache[objName] = objFound;
                }
            }
        });
        return objFound as v3dMesh;
    }

    // utility function envoked by almost all V3D-specific puzzles
    // retrieve all objects on the scene
    getAllObjectNames() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const objNameList = [];
        this.appInstance.scene.traverse((obj) => {
            if (this.notIgnoredObj(obj)) objNameList.push(obj.name);
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return objNameList;
    }

    findUniqueObjectName(name: string) {
        const objNameUsed = (name: string) => {
            return Boolean(this.getObjectByName(name));
        };
        while (objNameUsed(name)) {
            const r = name.match(/^(.*?)(\d+)$/);
            if (!r) {
                name += '2';
            } else {
                name = r[1] + (parseInt(r[2], 10) + 1);
            }
        }
        return name;
    }

    // utility function envoked by almost all V3D-specific puzzles
    // retrieve all objects which belong to the group
    getObjectNamesByGroupName(targetGroupName: any) {
        const objNameList: string[] = [];
        this.appInstance.scene.traverse((obj) => {
            if (this.notIgnoredObj(obj)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const groupNames = obj.groupNames;
                if (!groupNames) return;
                for (let i = 0; i < groupNames.length; i++) {
                    const groupName = groupNames[i];
                    if (groupName == targetGroupName) {
                        objNameList.push(obj.name);
                    }
                }
            }
        });
        return objNameList;
    }

    retrieveObjectNamesAcc(currObjNames: string | any[], acc: any[]) {
        let i;
        let newObj;
        if (typeof currObjNames == 'string') {
            acc.push(currObjNames);
        } else if (Array.isArray(currObjNames) && currObjNames[0] == 'GROUP') {
            newObj = this.getObjectNamesByGroupName(currObjNames[1]);
            for (i = 0; i < newObj.length; i++) acc.push(newObj[i]);
        } else if (
            Array.isArray(currObjNames) &&
            currObjNames[0] == 'ALL_OBJECTS'
        ) {
            newObj = this.getAllObjectNames();
            for (i = 0; i < newObj.length; i++) acc.push(newObj[i]);
        } else if (Array.isArray(currObjNames)) {
            for (i = 0; i < currObjNames.length; i++)
                this.retrieveObjectNamesAcc(currObjNames[i], acc);
        }
    }

    /**
     * Transform coordinates from one space to another
     * Can be used with Vector3 or Euler.
     */
    coordsTransform(coords: { x: any, y: any; z: any; }, from: string, to: string, noSignChange?: boolean | undefined) {
        if (from == to) return coords;

        const y = coords.y,
            z = coords.z;

        if (from == 'Z_UP_RIGHT' && to == 'Y_UP_RIGHT') {
            coords.y = z;
            coords.z = noSignChange ? y : -y;
        } else if (from == 'Y_UP_RIGHT' && to == 'Z_UP_RIGHT') {
            coords.y = noSignChange ? z : -z;
            coords.z = y;
        } else {
            console.error('coordsTransform: Unsupported coordinate space');
        }

        return coords;
    }

    /**
     * Retrieve coordinate system from the loaded scene
     */
    getCoordSystem() {
        const scene = this.appInstance.scene;

        if (
            scene &&
            'v3d' in scene.userData &&
            'coordSystem' in scene.userData.v3d
        ) {
            return scene.userData.v3d.coordSystem;
        } else {
            // COMPAT: <2.17, consider replacing to 'Y_UP_RIGHT' for scenes with unknown origin
            return 'Z_UP_RIGHT';
        }
    }

    // tweenCamera puzzle
    tweenCamera(posOrObj: string, targetOrObj: string, duration: number, doSlot: () => void, movementType: any) {
        let worldTarget;
        let worldPos;
        const camera = this.appInstance.getCamera();

        if (Array.isArray(posOrObj)) {
            worldPos = this._pGlob.vec3Tmp.fromArray(posOrObj);
            worldPos = this.coordsTransform(
                worldPos,
                this.getCoordSystem(),
                'Y_UP_RIGHT',
            );
        } else if (posOrObj) {
            const posObj = this.getObjectByName(posOrObj);
            if (!posObj) return;
            worldPos = posObj.getWorldPosition(this._pGlob.vec3Tmp);
        } else {
            // empty input means: don't change the position
            worldPos = camera.getWorldPosition(this._pGlob.vec3Tmp);
        }

        if (Array.isArray(targetOrObj)) {
            worldTarget = this._pGlob.vec3Tmp2.fromArray(targetOrObj);
            worldTarget = this.coordsTransform(
                worldTarget,
                this.getCoordSystem(),
                'Y_UP_RIGHT',
            );
        } else {
            const targObj = this.getObjectByName(targetOrObj);
            if (!targObj) return;
            worldTarget = targObj.getWorldPosition(this._pGlob.vec3Tmp2);
        }

        duration = Math.max(0, duration);

        if (this.appInstance.controls && this.appInstance.controls.tween) {
            // orbit and flying cameras
            if (!this.appInstance.controls.inTween) {
                this.appInstance.controls.tween(
                    worldPos,
                    worldTarget,
                    duration,
                    doSlot,
                    movementType,
                );
            }
        } else {
            // TODO: static camera, just position it for now
            if (camera.parent) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                camera.parent.worldToLocal(worldPos);
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            camera.position.copy(worldPos);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            camera.lookAt(worldTarget);
            doSlot();
        }
    }

    getMorphFactor(objName: string, targetName: string) {
        if (objName && targetName) {
            let obj = this.getObjectByName(objName);

            if (obj) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                obj = obj.resolveMultiMaterial()[0];
                if (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    obj.morphTargetDictionary && targetName in obj.morphTargetDictionary
                ) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const idx = obj.morphTargetDictionary[targetName];
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return obj.morphTargetInfluences[idx];
                }
            }
        }

        return 0;
    }

    setMorphFactor(objName: string, targetName: string, factor: any) {
        if (objName && targetName) {
            const obj = this.getObjectByName(objName);
            if (obj) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                obj.resolveMultiMaterial().forEach(function(objR) {
                    if (
                        objR.morphTargetDictionary &&
                        targetName in objR.morphTargetDictionary
                    ) {
                        const idx = objR.morphTargetDictionary[targetName];
                        objR.morphTargetInfluences[idx] = Number(factor);
                    }
                });

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore// COMPAT: 3.7.0, only to keep 3.7.1 stable (not require engine update)
                if (obj.getAuxClippingMeshes)
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    obj.getAuxClippingMeshes().forEach(function(objC) {
                        if (
                            objC.morphTargetDictionary &&
                            targetName in objC.morphTargetDictionary
                        ) {
                            const idx = objC.morphTargetDictionary[targetName];
                            objC.morphTargetInfluences[idx] = Number(factor);
                        }
                    });
            }
        }
    }

    setMorphFactorByItem(obj: Object3D<Event> | null, targetName: string, factor: number) {
        if (obj && targetName) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            obj.resolveMultiMaterial().forEach(function(objR) {
                if (
                    objR.morphTargetDictionary &&
                    targetName in objR.morphTargetDictionary
                ) {
                    const idx = objR.morphTargetDictionary[targetName];
                    objR.morphTargetInfluences[idx] = Number(factor);
                }
            });

            // COMPAT: 3.7.0, only to keep 3.7.1 stable (not require engine update)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (obj.getAuxClippingMeshes)
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                obj.getAuxClippingMeshes().forEach(function(objC) {
                    if (
                        objC.morphTargetDictionary &&
                        targetName in objC.morphTargetDictionary
                    ) {
                        const idx = objC.morphTargetDictionary[targetName];
                        if (targetName == 'doors_recess_top_corner')
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            console.log(objR.morphTargetInfluences[idx]);
                        objC.morphTargetInfluences[idx] = Number(factor);
                    }
                });
        }
    }

    // zoomCamera puzzle
    zoomCamera(zoomObjects: Array<any>, duration: number, doSlot: () => void) {
        duration = Math.max(0, duration);

        if (!zoomObjects.length) {
            return;
        }

        const camera = this.appInstance.getCamera();

        const pos = this._pGlob.vec3Tmp,
            target = this._pGlob.vec3Tmp2;
        v3d.CameraUtils.calcCameraZoomToObjectsParams(
            camera,
            zoomObjects,
            pos,
            target,
        );

        if (this.appInstance.controls && this.appInstance.controls.tween) {
            // orbit and flying cameras
            if (!this.appInstance.controls.inTween) {
                this.appInstance.controls.tween(pos, target, duration, doSlot);
            }
        } else {
            // TODO: static camera, just position it for now
            if (camera.parent) {
                camera.parent.worldToLocal(pos);
            }
            camera.position.copy(pos);
            camera.lookAt(target);
            doSlot();
        }
    }

    // utility function envoked by almost all V3D-specific puzzles
    // process object input, which can be either single obj or array of objects, or a group
    retrieveObjectNames(objNames: string) {
        const acc: any[] = [];
        this.retrieveObjectNamesAcc(objNames, acc);
        return acc.filter(function(name) {
            return name;
        });
    }

    // getAnimations puzzle
    getAnimations(objSelector: string) {
        const objNames = this.retrieveObjectNames(objSelector);

        const animations = [];
        for (let i = 0; i < objNames.length; i++) {
            const objName = objNames[i];
            if (!objName) continue;
            // use objName as animName - for now we have one-to-one match
            const action = v3d.SceneUtils.getAnimationActionByName(
                this.appInstance,
                objName,
            );
            if (action) animations.push(objName);
        }
        return animations;
    }

    /**
     * Get a scene that contains the root of the given action.
     */
    getSceneByAction(action: { getRoot: () => any; }) {
        const root = action.getRoot();
        let scene = root.type == 'Scene' ? root : null;
        root.traverseAncestors(function(ancObj: { type: string; }) {
            if (ancObj.type == 'Scene') {
                scene = ancObj;
            }
        });
        return scene;
    }

    /**
     * Get the current scene's framerate.
     */
    getSceneAnimFrameRate(scene: { userData: { animFrameRate: any; }; }) {
        if (scene && 'animFrameRate' in scene.userData) {
            return scene.userData.animFrameRate;
        }
        return 24;
    }

    initAnimationMixer() {
        const onMixerFinished = (e: any) => {
            let i;
            const cb = this._pGlob.animMixerCallbacks;
            const found = [];
            for (i = 0; i < cb.length; i++) {
                if (cb[i][0] == e.action) {
                    cb[i][0] = null; // desactivate
                    found.push(cb[i][1]);
                }
            }
            for (i = 0; i < found.length; i++) {
                found[i]();
            }
        };

        return () => {
            if (
                this.appInstance.mixer && !this.appInstance.mixer.hasEventListener('finished', onMixerFinished)
            )
                this.appInstance.mixer.addEventListener(
                    'finished',
                    onMixerFinished,
                );
        };
    }

    // animation puzzles

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    operateAnimation(operation, actions, from, to, loop, speed, callback, rev) {
        // if (!animations) return;
        // input can be either single obj or array of objects
        // if (typeof animations == 'string') animations = [animations];

        const processAnimation = (action: {
            isRunning?: any;
            time?: any;
            reset?: any;
            loop?: any;
            repetitions?: any;
            timeScale?: any;
            timeStart?: any;
            getClip?: any;
            paused?: any;
            play?: any;
            stop?: any;
            getRoot?: () => any;
        }) => {
            // const action = v3d.SceneUtils.getAnimationActionByName(
            //     this.appInstance,
            //     animName
            // );
            if (!action) return;
            let j;
            switch (operation) {
                case 'PLAY':
                    // eslint-disable-next-line no-case-declarations
                    let currentTime;
                    if (action.isRunning) currentTime = action.time;
                    // if (!action.isRunning()) {
                    action.reset();
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (loop && loop != 'AUTO') action.loop = v3d[loop];
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // eslint-disable-next-line no-case-declarations
                    let scene = this.getSceneByAction(action);
                    // eslint-disable-next-line no-case-declarations
                    let frameRate = this.getSceneAnimFrameRate(scene);

                    action.repetitions = Infinity;

                    // eslint-disable-next-line no-case-declarations
                    let timeScale = Math.abs(parseFloat(speed));
                    if (rev) timeScale *= -1;

                    action.timeScale = timeScale;
                    action.timeStart = from !== null ? from / frameRate : 0;
                    if (to !== null) {
                        action.getClip().duration = to / frameRate;
                    } else {
                        action.getClip().resetDuration();
                    }
                    action.time =
                        currentTime ??
                        (timeScale >= 0
                            ? action.timeStart
                            : action.getClip().duration);

                    action.paused = false;
                    action.play();

                    // push unique callbacks only
                    // eslint-disable-next-line no-case-declarations
                    let callbacks = this._pGlob.animMixerCallbacks;
                    // eslint-disable-next-line no-case-declarations
                    let found = false;

                    for (j = 0; j < callbacks.length; j++)
                        if (
                            callbacks[j][0] == action &&
                            callbacks[j][1] == callback
                        )
                            found = true;

                    if (!found)
                        this._pGlob.animMixerCallbacks.push([ action, callback ]);
                    // } else {
                    // }
                    break;
                case 'STOP':
                    action.stop();

                    // remove callbacks
                    callbacks = this._pGlob.animMixerCallbacks;
                    for (j = 0; j < callbacks.length; j++)
                        if (callbacks[j][0] == action) {
                            callbacks.splice(j, 1);
                            j--;
                        }

                    break;
                case 'PAUSE':
                    action.paused = true;
                    break;
                case 'RESUME':
                    action.paused = false;
                    break;
                case 'SET_FRAME':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    scene = this.getSceneByAction(action);
                    frameRate = this.getSceneAnimFrameRate(scene);
                    action.time = from ? from / frameRate : 0;
                    action.play();
                    action.paused = true;
                    break;
                case 'SET_SPEED':
                    timeScale = parseFloat(speed);
                    action.timeScale = rev ? -timeScale : timeScale;
                    break;
            }
        };

        for (let i = 0; i < actions.length; i++) {
            processAnimation(actions[i]);
        }

        this.initAnimationMixer()();
    }

    // cloneObject puzzle
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    cloneObject(objName, recurcive) {
        if (!objName) return;
        const obj = this.getObjectByName(objName);
        if (!obj) return;
        const newObj = obj.clone(recurcive ?? false);
        newObj.name = this.findUniqueObjectName(obj.name);
        this.appInstance.scene.add(newObj);
        return newObj.name;
    }

    /**
     * Verge3D euler rotation to Blender/Max shortest.
     * 1) Convert from intrinsic rotation (v3d) to extrinsic XYZ (Blender/Max default
     *    order) via reversion: XYZ -> ZYX
     * 2) swizzle ZYX->YZX
     * 3) choose the shortest rotation to resemble Blender's behavior
     */
    eulerV3DToBlenderShortest() {
        const eulerTmp = new v3d.Euler();
        const eulerTmp2 = new v3d.Euler();
        const vec3Tmp = new v3d.Vector3();

        return (euler: Euler, dest: { copy?: any; x?: any, y?: any; z?: any; }) => {
            const eulerBlender = eulerTmp.copy(euler).reorder('YZX');
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const eulerBlenderAlt = eulerTmp2.copy(eulerBlender).makeAlternative();

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const len = eulerBlender.toVector3(vec3Tmp).lengthSq();
            const lenAlt = eulerBlenderAlt.toVector3(vec3Tmp).lengthSq();

            dest.copy(len < lenAlt ? eulerBlender : eulerBlenderAlt);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return this.coordsTransform(dest, 'Y_UP_RIGHT', 'Z_UP_RIGHT');
        };
    }

    // utility function envoked by almost all V3D-specific puzzles
    // retrieve all objects which belong to the group
    // setObjTransform puzzle
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setObjTransform(objSelector, isWorldSpace, mode, vector, offset) {
        let euler;
        const x = vector[0];
        const y = vector[1];
        const z = vector[2];

        const objNames = this.retrieveObjectNames(objSelector);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        function setObjProp(obj, prop, val) {
            if (!offset) {
                obj[mode][prop] = val;
            } else {
                if (mode != 'scale') obj[mode][prop] += val;
                else obj[mode][prop] *= val;
            }
        }

        const inputsUsed = this._pGlob.vec3Tmp.set(
            Number(x !== ''),
            Number(y !== ''),
            Number(z !== ''),
        );
        const coords = this._pGlob.vec3Tmp2.set(x || 0, y || 0, z || 0);

        if (mode === 'rotation') {
            // rotations are specified in degrees
            coords.multiplyScalar(v3d.MathUtils.DEG2RAD);
        }

        const coordSystem = this.getCoordSystem();

        this.coordsTransform(inputsUsed, coordSystem, 'Y_UP_RIGHT', true);
        this.coordsTransform(
            coords,
            coordSystem,
            'Y_UP_RIGHT',
            mode === 'scale',
        );

        for (let i = 0; i < objNames.length; i++) {
            const objName = objNames[i];
            if (!objName) continue;

            const obj = this.getObjectByName(objName);
            if (!obj) continue;

            if (isWorldSpace && obj.parent) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                obj.matrixWorld.decomposeE(
                    obj.position,
                    obj.rotation,
                    obj.scale,
                );

                if (inputsUsed.x) setObjProp(obj, 'x', coords.x);
                if (inputsUsed.y) setObjProp(obj, 'y', coords.y);
                if (inputsUsed.z) setObjProp(obj, 'z', coords.z);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                obj.matrixWorld.composeE(obj.position, obj.rotation, obj.scale);
                obj.matrix.multiplyMatrices(
                    this._pGlob.mat4Tmp.copy(obj.parent.matrixWorld).invert(),
                    obj.matrixWorld,
                );
                obj.matrix.decompose(obj.position, obj.quaternion, obj.scale);
            } else if (mode === 'rotation' && coordSystem == 'Z_UP_RIGHT') {
                // Blender/Max coordinates

                // need all the rotations for order conversions, especially if some
                // inputs are not specified
                euler = this.eulerV3DToBlenderShortest()(
                    obj.rotation,
                    this._pGlob.eulerTmp,
                );
                this.coordsTransform(euler, coordSystem, 'Y_UP_RIGHT');

                if (inputsUsed.x)
                    euler.x = offset ? euler.x + coords.x : coords.x;
                if (inputsUsed.y)
                    euler.y = offset ? euler.y + coords.y : coords.y;
                if (inputsUsed.z)
                    euler.z = offset ? euler.z + coords.z : coords.z;

                /**
                 * convert from Blender/Max default XYZ extrinsic order to v3d XYZ
                 * intrinsic with reversion (XYZ -> ZYX) and axes swizzling (ZYX -> YZX)
                 */
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                euler.order = 'YZX';
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                euler.reorder(obj.rotation.order);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                obj.rotation.copy(euler);
            } else if (mode === 'rotation' && coordSystem == 'Y_UP_RIGHT') {
                // Maya coordinates

                // Use separate rotation interface to fix ambiguous rotations for Maya,
                // might as well do the same for Blender/Max.
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const rotUI = this.RotationInterface.initObject(obj);
                euler = rotUI.getUserRotation(this._pGlob.eulerTmp);
                // TODO(ivan): this probably needs some reasonable wrapping
                if (inputsUsed.x)
                    euler.x = offset ? euler.x + coords.x : coords.x;
                if (inputsUsed.y)
                    euler.y = offset ? euler.y + coords.y : coords.y;
                if (inputsUsed.z)
                    euler.z = offset ? euler.z + coords.z : coords.z;

                rotUI.setUserRotation(euler);
                rotUI.getActualRotation(obj.rotation);
            } else {
                if (inputsUsed.x) setObjProp(obj, 'x', coords.x);
                if (inputsUsed.y) setObjProp(obj, 'y', coords.y);
                if (inputsUsed.z) setObjProp(obj, 'z', coords.z);
            }

            obj.updateMatrixWorld(true);
        }
    }

    // loadScene puzzle
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    loadScene(url, sceneName, loadCb, progCb, errorCb) {
        this.appInstance.unload();

        // clean object cache
        this._pGlob.objCache = {};

        this._pGlob.percentage = 0;
        this.appInstance.loadScene(
            url,
            (loadedScene) => {
                this.appInstance.enableControls();
                loadedScene.name = sceneName;

                this._pGlob.percentage = 100;
                loadCb();
            },
            (percentage) => {
                this._pGlob.percentage = percentage;
                progCb();
            },
            errorCb,
        );
    }

    // appendScene puzzle
    appendScene(
        url: string,
        sceneName: string,
        loadCameras: boolean,
        loadLights: boolean,
        loadCb: () => void,
        progCb: () => void,
        errorCb: (error: any) => void,
    ) {
        this._pGlob.percentage = 0;

        this.appInstance.appendScene(
            url,
            (loadedScene) => {
                loadedScene.name = sceneName;
                this._pGlob.percentage = 100;
                loadCb();
            },
            (percentage) => {
                this._pGlob.percentage = percentage;
                progCb();
            },
            errorCb,
            loadCameras,
            loadLights,
        );
    }

    // setCameraParam puzzle
    setCameraParam(type: string, objSelector: string, param: any) {
        const objNames = this.retrieveObjectNames(objSelector);

        objNames.forEach((objName) => {
            if (!objName) return;

            const obj = this.getObjectByName(objName);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!obj || !obj.isCamera) return;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!(obj.isPerspectiveCamera || obj.isOrthographicCamera)) {
                console.error(
                    'setCameraParam: Incompatible camera type, have to be perspective or orthographic',
                );
                return;
            }

            let isSetOrbitParam = false;
            switch (type) {
                case 'ORBIT_MIN_DISTANCE_PERSP':
                case 'ORBIT_MAX_DISTANCE_PERSP':
                case 'ORBIT_MIN_ZOOM_ORTHO':
                case 'ORBIT_MAX_ZOOM_ORTHO':
                case 'ORBIT_MIN_VERTICAL_ANGLE':
                case 'ORBIT_MAX_VERTICAL_ANGLE':
                case 'ORBIT_MIN_HORIZONTAL_ANGLE':
                case 'ORBIT_MAX_HORIZONTAL_ANGLE':
                case 'ORBIT_ALLOW_TURNOVER':
                    isSetOrbitParam = true;
                    break;
            }
            const isSetControlsParam =
                [
                    'ROTATION_SPEED',
                    'MOVEMENT_SPEED',
                    'ALLOW_PANNING',
                    'ALLOW_ZOOM',
                    'KEYBOARD_CONTROLS',
                ].includes(type) || isSetOrbitParam;
            if (isSetControlsParam) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (!obj.controls) {
                    console.error(
                        'setCameraParam: The "' +
                        objName +
                        '" camera has no controller',
                    );
                    return;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                } else if (isSetOrbitParam && obj.controls.type != 'ORBIT') {
                    console.error(
                        'setCameraParam: Incompatible camera controller',
                    );
                    return;
                }
            }

            switch (type) {
                case 'FIELD_OF_VIEW':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (obj.isPerspectiveCamera) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        obj.fov = param;
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        obj.updateProjectionMatrix();
                    } else {
                        console.error(
                            'setCameraParam: Incompatible camera type, have to be perspective',
                        );
                        return;
                    }
                    break;
                case 'ORTHO_SCALE':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (obj.isOrthographicCamera) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        obj.zoom = param;
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        obj.updateProjectionMatrix();
                    } else {
                        console.error(
                            'setCameraParam: Incompatible camera type, have to be orthographic',
                        );
                        return;
                    }
                    break;
                case 'ROTATION_SPEED':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    obj.controls.rotateSpeed = param;
                    break;
                case 'MOVEMENT_SPEED':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    obj.controls.moveSpeed = param;
                    break;
                case 'ALLOW_PANNING':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    obj.controls.enablePan = param;
                    break;
                case 'ALLOW_ZOOM':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    obj.controls.enableZoom = param;
                    break;
                case 'KEYBOARD_CONTROLS':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    obj.controls.enableKeys = param;
                    break;
                case 'ORBIT_MIN_DISTANCE_PERSP':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (obj.isPerspectiveCamera) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        obj.controls.orbitMinDistance = param;
                    } else {
                        console.error(
                            'setCameraParam: Incompatible camera type, have to be perspective',
                        );
                        return;
                    }
                    break;
                case 'ORBIT_MAX_DISTANCE_PERSP':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (obj.isPerspectiveCamera) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        obj.controls.orbitMaxDistance = param;
                    } else {
                        console.error(
                            'setCameraParam: Incompatible camera type, have to be perspective',
                        );
                        return;
                    }
                    break;
                case 'ORBIT_MIN_ZOOM_ORTHO':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (obj.isOrthographicCamera) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        obj.controls.orbitMinZoom = param;
                    } else {
                        console.error(
                            'setCameraParam: Incompatible camera type, have to be orthographic',
                        );
                        return;
                    }
                    break;
                case 'ORBIT_MAX_ZOOM_ORTHO':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (obj.isOrthographicCamera) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        obj.controls.orbitMaxZoom = param;
                    } else {
                        console.error(
                            'setCameraParam: Incompatible camera type, have to be orthographic',
                        );
                        return;
                    }
                    break;
                case 'ORBIT_MIN_VERTICAL_ANGLE':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    obj.controls.orbitMinPolarAngle = v3d.MathUtils.degToRad(param);
                    break;
                case 'ORBIT_MAX_VERTICAL_ANGLE':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    obj.controls.orbitMaxPolarAngle = v3d.MathUtils.degToRad(param);
                    break;
                case 'ORBIT_MIN_HORIZONTAL_ANGLE':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    obj.controls.orbitMinAzimuthAngle = v3d.MathUtils.degToRad(param);
                    break;
                case 'ORBIT_MAX_HORIZONTAL_ANGLE':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    obj.controls.orbitMaxAzimuthAngle = v3d.MathUtils.degToRad(param);
                    break;
                case 'ORBIT_ALLOW_TURNOVER':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    obj.controls.orbitEnableTurnover = param;
                    break;
                case 'CLIP_START':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    obj.near = param;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    obj.updateProjectionMatrix();
                    break;
                case 'CLIP_END':
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    obj.far = param;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    obj.updateProjectionMatrix();
                    break;
            }

            if (isSetControlsParam) this.appInstance.enableControls();
        });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    vergeToHtmlCoords(x, y, z) {
        const projected = new v3d.Vector3(x, y, z);
        const camera = this.appInstance.getCamera(true);
        camera.updateMatrixWorld();
        projected.project(camera);

        let isBehindCamera = false;
        const farNearCoeff =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (camera.far + camera.near) / (camera.far - camera.near);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (camera.isPerspectiveCamera) {
            isBehindCamera = projected.z > farNearCoeff;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
        } else if (camera.isOrthographicCamera) {
            isBehindCamera = projected.z < -farNearCoeff;
        }

        if (isBehindCamera) {
            // behind the camera, just move the element out of the sight
            projected.x = projected.y = -1e5;
        } else {
            projected.x =
                (0.5 + projected.x / 2) *
                this.appInstance.container.offsetWidth;
            projected.y =
                (0.5 - projected.y / 2) *
                this.appInstance.container.offsetHeight;
        }

        return projected;
    }

    // // bindHTMLObject puzzle
    // bindHTMLObject(objName, id, isParent) {
    //     if (!objName)
    //         return;
    //     var elem = this.getElement(id, isParent);
    //     if (!elem)
    //         return;
    //     var obj = this.getObjectByName(objName);
    //     if (!obj)
    //         return;
    //     var projected = new v3d.Vector3();
    //     elem.style.top = 0;
    //     elem.style.left = 0;
    //     function bindHTMLUpdateCb() {
    //         var camera = this.appInstance.getCamera(true);
    //         camera.updateMatrixWorld();
    //         obj.getWorldPosition(projected).project(camera);
    //
    //         var isBehindCamera = false;
    //         var farNearCoeff = (camera.far + camera.near) / (camera.far - camera.near);
    //         if (camera.isPerspectiveCamera) {
    //             isBehindCamera = projected.z > farNearCoeff;
    //         } else if (camera.isOrthographicCamera) {
    //             isBehindCamera = projected.z < -farNearCoeff;
    //         }
    //
    //         if (isBehindCamera) {
    //             // behind the camera, just move the element out of the sight
    //             projected.x = projected.y = -1e5;
    //         } else {
    //             projected.x = (0.5 + projected.x / 2) * this.appInstance.container.offsetWidth;
    //             projected.y = (0.5 - projected.y / 2) * this.appInstance.container.offsetHeight;
    //         }
    //
    //         elem.style.transform = "translate(" + projected.x + "px, " + projected.y + "px)";
    //     }
    //
    //     this._pGlob.bindHTMLCallbackInfo.push({
    //         elem: elem,
    //         obj: obj,
    //         callback: bindHTMLUpdateCb
    //     });
    //
    //     this.appInstance.renderCallbacks.push(bindHTMLUpdateCb);
    //     if (v3d.PL.editorRenderCallbacks)
    //         v3d.PL.editorRenderCallbacks.push([this.appInstance, bindHTMLUpdateCb]);
    // }

    // createTextObject puzzle

    createTextObject(
        name: string,
        text: any,
        font: any,
        size: any,
        height: number,
        alignX: any,
        alignY: any,
        segments: any,
        bevelThickness: number,
        bevelSize: number,
        doCb: (arg0: Mesh<any, any>) => void,
    ) {
        const oldObj = this.appInstance.scene.getObjectByName(name);
        console.log(oldObj);
        const material = new v3d.MeshStandardMaterial({
            color: 'white',
            roughness: 1.0,
            metalness: 0.0,
            side: height == 0 ? v3d.DoubleSide : v3d.FrontSide,
        });

        material.name = name + 'Material';

        const obj = new v3d.Mesh(new v3d.BufferGeometry(), material);
        obj.name = name;

        this.appInstance.scene.add(obj);

        // clean object cache
        this._pGlob.objCache = {};

        const loader = new v3d.TTFLoader();
        loader.setCrossOrigin('Anonymous');

        v3d.loadModule(
            'opentype.js',
            function() {
                loader.load(font, function(json) {
                    // NOTE: fix possible double-delete errors
                    if (oldObj && oldObj.parent) {
                        oldObj.parent.remove(oldObj);
                    }

                    const font = new v3d.Font(json);


                    const geometry = new v3d.TextGeometry(text, {
                        font: font,

                        size: size,
                        height: height,
                        curveSegments: segments,

                        bevelThickness: bevelThickness,
                        bevelSize: bevelSize,
                        bevelEnabled: bevelSize > 0 || bevelThickness > 0,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        alignX: alignX,
                        alignY: alignY,
                    });

                    obj.geometry = geometry;

                    doCb(obj);
                });
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            function() {
                console.error(
                    'create text object: opentype.js module not found, please copy it to your app directory',
                );
            },
        );
    }
}
