import { Filter } from '../utils/expFilter';

export class Camera {
    room;
    constructor(camera, v3dtools, room) {
        this.room = room;
        this.v3dtools = v3dtools;
        this.camera = camera;
        // this.camera.position.set(1, 1, 5);
        this.isZoming = false;
        this.firstUpdate = true;
        this.width = 1.5;
        this.height = 1.5;
        this.filter = new Filter(0.05);
    }

    continuousZoom() {
        requestAnimationFrame(() => {
            const maxSize = Math.max(this.width, this.height * 1.1);
            const zoom = 2 / Math.pow(maxSize, 0.8) - 0.03;

            if (this.firstUpdate) {
                this.camera.zoom = zoom;
                this.isZoming = false;
                setTimeout(() => {
                    this.firstUpdate = false;
                }, 1000);
            } else {
                this.camera.zoom = this.filter.newValue(zoom);
            }
            this.continuousZoom();
        });
    }

    updateCamZoom(width, height) {
        this.width = width;
        this.height = height;

        // if (this.isZoming) {
        //     return
        // }
        // this.isZoming = true;
        // const duration = 0.5;
        // const maxSize = Math.max(this.width, this.height*1.1);
        // const zoom =  0.8/Math.pow(maxSize, 0.8) - 0.03;
        // let minZoom;
        // let maxZoom;
        // if (zoom > this.camera.zoom) {
        //     maxZoom = zoom;
        //     minZoom = this.camera.zoom;
        // } else {
        //     minZoom = zoom;
        //     maxZoom = this.camera.zoom;
        // }
        //
        // if (this.firstUpdate) {
        //     this.camera.zoom = zoom;
        //     this.isZoming = false;
        //     setTimeout(()=>{
        //         this.firstUpdate = false;
        //     }, 1000)
        //     return;
        // }
        //
        // tweenVal(
        //     this.camera.zoom,
        //     zoom,
        //     duration,
        //     (tweenZoom)=>{
        //         let smoothstep = v3d.MathUtils.smootherstep(tweenZoom,minZoom,maxZoom)
        //         this.camera.zoom = (1 - smoothstep) * minZoom  + smoothstep * maxZoom;
        //     },
        //     ()=>{
        //         this.isZoming = false;
        //     })
    }

    updateCam(viewCenter, position, customdDuration) {
        let duration = customdDuration ?? 0.01;
        // if (this.firstUpdate) {
        //     duration = 0.01;
        //     setTimeout(() => {
        //         this.firstUpdate = false;
        //     }, 1000);
        //     // return;
        // }

        this.v3dtools.tweenCamera(
            [ position.x, -position.z, position.y ], //x, -z, y
            new v3d.Vector3(viewCenter.x, viewCenter.z, viewCenter.y).toArray(),
            duration,
            () => {
                // this.updateCamZoom(width, height);
            },
            0,
        );
    }

}
