import { init } from './v3d/init';
import { app } from './Engine/app';
import { engineConfig } from './Constants/3dConfig';

export default async function() {
    // const container = document.getElementById('v3d-container');
    const appPromise = init({
        ...engineConfig,
        preloaderProgressCb: function(percentage) {
        },
    });

    const appInstance = await appPromise;
    appInstance.renderer.setPixelRatio(
        devicePixelRatio > 1 ? devicePixelRatio : engineConfig.pixelRatio,
    );
    if (engineConfig.SSAA)
        appInstance.enableSSAA(engineConfig.SSAA);
    appInstance.onResize();
    appInstance.controls.enablePan = engineConfig.enablePan;
    appInstance.controls.enableZoom = engineConfig.enableZoom;
    appInstance.controls.enableKeys = engineConfig.enableKeys;
    appInstance.renderer.localClippingEnabled = engineConfig.localClippingEnabled;
    await app(appInstance);
    // appPromise.then((appInstance) => {
    //
    // });
}
