import {v3dMaterial, v3dMesh} from '@/Interfaces/common';
import { IV3D } from '@/Interfaces/v3d';
declare let v3d: IV3D;
export default function changeColor(material: v3dMaterial, node: string, hexColor: string) {
    if (!material) return;
    // console.log(material)
    const RgbExternalIndex = material.nodeRGBMap[node];
    if (typeof RgbExternalIndex === 'undefined') return;
    const color = new v3d.Color(hexColor);
    color.convertSRGBToLinear();
    // item.material.nodeRGB[RgbExternalIndex].x = color.r;
    // item.material.nodeRGB[RgbExternalIndex].y = color.g;
    // item.material.nodeRGB[RgbExternalIndex].z = color.b;

    // @ts-ignore
    material.nodeRGB[RgbExternalIndex] = new v3d.Vector4(color.r, color.g, color.b, 1)
    material.needsUpdate = true;
}
