import { IV3D } from '@/Interfaces/v3d';
import { iApp } from '@/Interfaces/app';

declare let v3d: IV3D;
declare let app: iApp;

export function loader(asset: string, afterLoadCb: (scene: typeof v3d.scene)=>void, onprogress: (percentage:number)=>void, app: iApp):Promise<unknown> {
    const promise = new Promise((resolve, reject) => {
        app.appendScene(
            asset,
            (loadedScene) => {
                // this.actions = this.app.actions;
                if (afterLoadCb)
                    afterLoadCb(loadedScene);
                resolve(loadedScene);
            },
            (percentage) => {
                if (onprogress) onprogress(percentage);
            },
            () => {},
            false,
            false,
        );
    });
    return promise;
}

const gltfLoader = new v3d.GLTFLoader();

export const loadModel = function(url, modelName?) {
    return new Promise((resolve, reject) => {
        gltfLoader.load(url, function(gltf) {
            resolve(gltf.scene);
            // gltf.scene.traverse((obj) => {
            //     if (obj.name === modelName) {
            //         resolve(obj);
            //     }
            // });
            // reject();
        });
    });
};