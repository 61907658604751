export const engineConfig = {
    important: true,
    fadeAnnotations: true,
    useBkgTransp: false,
    preserveDrawBuf: true,
    useCompAssets: false,
    useFullscreen: false,
    useCustomPreloader: true,
    sceneURL: 'app.gltf',
    visualLogicURL: 'visual_logic.js',
    enablePan: true,
    enableZoom: true,
    enableKeys: true,
    SSAA: 0, //0 disable
    pixelRatio: 1,
    localClippingEnabled: false,
    boxHelper: false,
};

export const gridEnabled = false;
export const gridSize = 50;
export const gridDivisions = 50;
